import React from "react"
import PropTypes from "prop-types"
import NavTop from "../NavTop/NavTop"

import "./PageHeader.scss"

const PageHeader = ({ h1, desc, date, notGenerator, language }) => (
  <header className="PageHeader">
    <NavTop notGenerator={notGenerator} language={language} />
    <div className="container-fluid">
      <div className="row">
        <div className={`PageHeader__content ${date && `post`}`}>
          <h1>{h1}</h1>
          {date && <p>{date}</p>}
          <p className={`${date ? "PageHeader__postDescription" : ""}`}>
            {desc}
          </p>
        </div>
      </div>
    </div>
  </header>
)

PageHeader.propTypes = {
  h1: PropTypes.string,
  desc: PropTypes.string,
  date: PropTypes.string,
  notGenerator: PropTypes.bool,
}

PageHeader.defaultProps = {
  h1: "Heading...",
  desc: "Description...",
  date: null,
  notGenerator: false,
}

export default PageHeader
